<template>
    <div>
        <div class="tipsContent">
            <div class="tipHead">注意事项</div>
            <div v-if="['crossevent'].includes(event.tenant_code)" class="tipContent">
                <p>
                    <span>*</span>
                    请务必清晰拍照，提交后不能再修改！
                </p>
                <p>
                    <span>*</span>
                    请将发票原件与会议材料一并寄往项目组。
                </p>
            </div>
            <div v-if="!['novartis','crossevent'].includes(event.tenant_code)" class="tipContent">
                <p>
                    <span>*</span>
                    请务必清晰拍照，提交后不能再修改！
                </p>
                <p>
                    <span>*</span>
                    请将发票带回公司，纸质发票需投递至办事处信箱，或快递至中智公司；
                </p>
            </div>
            <div v-if="['novartis'].includes(event.tenant_code)" class="tipContent">
                <p>
                    <span>*</span>
                    如遇支付问题,请致电<span class="phone" @click="ihtml.telCall('01056304858')">客服中心</span>
                </p>
                <p>
                    <span>*</span>
                    上传发票请刮开密码涂层
                </p>
            </div>
            <template v-if="['novartis'].includes(event.tenant_code)">
                <div class="tipHead">发票信息</div>
                <div class="tipContent">
                    <p>发票抬头：{{qrCodeContent.name}}</p>
                    <p>纳税人登记号：{{qrCodeContent.taxPayerCode}}</p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "Tips",
    props: ["qrCodeContent"],
    computed: {
        ...mapGetters(["event"])
    },
};
</script>

<style scoped lang="scss">
    
    .tipsContent{
        background: #fff;
        padding: .1rem .2rem .25rem .2rem;
        border-top-left-radius: .2rem;
        border-top-right-radius: .2rem;
    }
    .tipHead{
        color: #5B6C76;
        text-align: left;
        font-size: .15rem;
        margin-top: .2rem;
    }
    .tipContent{
        padding-top: .1rem;
    }
    .tipContent p{
        text-align: left;
        margin-bottom: .05rem;
        color: #13161B;
        font-size: .12rem;
    }
    .tipContent span{
        color: #FC3258;
    }
    .tipContent .phone{
        color:  var(--themeColor);
        text-decoration: underline;
    }
</style>