<template>
    
<Popup v-model="isShow" position="bottom" getContainer="body">
    <div class="upload-invoice_page" @click="isShow = false">
        <div class="upload-invoice">
            <p class="section-title">添加发票</p>
            <div class="upload-section" @click.stop="uploadPaperType">
                <div>
                    <Svgs color="#13161B" name="icontupian"></Svgs>
                </div>
                <div>
                    <p>纸质发票拍摄</p>
                    <p>纸质发票，请拍照上传</p>
                </div>
            </div>
            <div class="upload-section" @click.stop="uploadFileType">
                <div>
                    <Svgs color="#13161B" name="icon-PDF"></Svgs>
                </div>
                <div>
                    <p>电子发票上传</p>
                    <p>电子发票，请选择原件上传</p>
                </div>
            </div>
            <div class="upload-section" @click.stop="wxCard" v-if="wxCardInfo.wxCardShow">
                <div>
                    <Svgs color="#13161B" name="icon-weixin"></Svgs>
                </div>
                <div>
                    <p>微信卡包</p>
                    <p>微信卡包中的发票，请直接导入</p>
                </div>
            </div>
            <div class="close">
                取消
            </div>
        </div>

        <BtnGroup ref="btnGroup" :btnList="btnList"></BtnGroup>
    </div>
    
</Popup>
</template>

<script>
import { Popup } from 'vant';
import BtnGroup from "./BtnGroup";
import { mapGetters } from "vuex";
import { UploadPlugOCR,UploadPlug} from "smart-filer-vue";
import Dialog from "vant/lib/dialog";
import { Indicator, Toast } from 'mint-ui';
import {
    SmartStorage
} from 'smart-core-util'
let wx = window.wx
import { miceService } from "@/service/miceService.js";
import { getCookie } from 'tiny-cookie'
const uploadInstance = new UploadPlugOCR({
  host: process.env.VUE_APP_GATEWAY
});
const uploadInstance2 = new UploadPlug({
  host: process.env.VUE_APP_GATEWAY
});
export default {
    name: "UploadInvoice",
    components: {
        BtnGroup,
        Popup
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    data() {
        return {
            btnList: [],
            btnList1: [
                {
                    txt: "拍照",
                    callback: this.graph
                },
                {
                    txt: "从相册选取",
                    callback: this.album
                }
            ],
            btnList2: [
                {
                    txt: "本地文件上传",
                    callback: this.album
                }
            ],
            wxCardInfo: {},
            isShow: false
        };
    },
    created(){
        window.getCardInfo = this.getCardInfo;
    },
    async mounted(){
        this.wxCardInfo = await this.wcCardSetting()
    },
    methods: {
        show(f){
            this.isShow = f
        },
        wcCardSetting(){
            let params = {
                collection: "cfg-imeal-wxCard",
                useCaching: false,
                cachingKey: "",
                filter: {
                    'tenantId': getCookie('tenant_id'),
                },
                projection: {
                    appId: 1,
                    sid: 1,
                    wxCardShow: 1
                }
            };
            if(SmartStorage.get('gray') || getCookie('gray')){
                    params.filter.tenantId = getCookie('tenant_id') + '-gray'
            }
            if(process.env.NODE_ENV == 'development'){
                params.filter.tenantId = 'development'
            }
            return new Promise((resolve, reject) => {   
                miceService.getsettings(params).then(res => {
                    if (res && res.success && res.content) {
                        resolve(res.content)
                    }else{
                        resolve({})
                    }
                }).catch(err => {
                    reject(false)
                })
            })
        },
        async wxCard(){
            SmartStorage.set('uploadMethod',2)
            let _this = this
            var ua = navigator.userAgent.toLowerCase();
            // 企业微信环境
            if(/micromessenger/i.test(ua)){
                let wvRes = await this.GenerateWechatJsSDKParams()
                wx.config({
                    beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    // appId: 'ww8e54ffd2ad46e81e', // 必填，企业微信的corpIDww7beb2b180d97789b
                    appId: this.wxCardInfo.appId, // 必填，企业微信的corpID  石药prd用石药appid
                    timestamp: wvRes.timestamp, // 必填，生成签名的时间戳
                    nonceStr: wvRes.nonceStr, // 必填，生成签名的随机串
                    signature: wvRes.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                    jsApiList: ['scanQRCode','chooseInvoice'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                });
                wx.error(function (res) {
                    alert('Config注入失败',res);
                });
                let weChaRes = await _this.GetPullInvoiceParam()
                wx.ready(function(){
                    wx.invoke('chooseInvoice', {
                        'timestamp': weChaRes.timestamp, // 卡券签名时间戳 
                        'nonceStr': weChaRes.nonceStr, // 卡券签名随机串 
                        'signType': weChaRes.signType, // 签名方式，默认'SHA1' 
                        'cardSign': weChaRes.cardSign, // 卡券签名 
                    }, function(res) {
                        _this.getCardInfo(JSON.parse(res.choose_invoice_info))
                    });
                });
                return
            }
            
            if(window.flutter_inappwebview){
                window.flutter_inappwebview.callHandler("App.addWeChatResultJS", 'getCardInfo');
                window.flutter_inappwebview.callHandler("App.openWeChatInvoice",'');
            return
            }

            var options = {
                loadUrl: "",
                method: "SMWXApi.openWXInvoice",
                postData: "",
                callback: function(data) {
                    if(data){
                        _this.getCardInfo(data)
                    }
                }
            };
            this.iJsBridge.call(options);
        },
        getCardInfo(item_list){
            let that = this
            that.isShow = false
            item_list.map((item) => {
                delete item.app_id
            })
            let params = {
                item_list: item_list,
                sid: this.wxCardInfo.sid
            }
            var ua = navigator.userAgent.toLowerCase();
            // 企业微信环境
            if(/micromessenger/i.test(ua)){
                params.api_key = 1
            }else{
                params.api_key = 0
            }

            miceService.getinvoicebatch(params).then(res => {
                if(res && res.success){
                    let fileKeyS = []
                    res.content.map((item) => {
                        item.fileKey = item.fileContent.fileKey
                        item.fileType = item.fileContent.fileType
                        item.filePath = item.fileContent.url
                        fileKeyS.push(item.fileContent.fileKey)
                    })
                    // this.ocrAsync(res.content)
                    this.mappingAsync(fileKeyS,res.content)
                }
            }).catch(err => {
            })
        },

        GetPullInvoiceParam(){
            let params = {
                sid: this.wxCardInfo.sid,// 石药使用
            }
            return new Promise((resolve,reject) => {
                miceService.GetPullInvoiceParam(params).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        GenerateWechatJsSDKParams(){
            let params = {
                sid: this.wxCardInfo.sid,
                currUrl: window.document.location.href
            }
            return new Promise((resolve,reject) => {
                miceService.GenerateWechatJsSDKParams(params).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            }) 
        },
        openBtnGroup(btnList) {
            this.btnList = btnList
            this.$refs.btnGroup.openPop();
        },
        //带框拍照
        openGraph() {
            this.$refs.btnGroup.openPop();
            let _this = this;
            const options = {
                loadUrl: "",
                method: "SMImage.openCamera",
                postData: {
                    showCameraBtn: true,
                },
                callback: data => {
                    let blob = this.dataURLtoBlob(data)
                    let file = this.blobToFile(blob,  this.randomString(7) + ".png")
                    let fileList = [{
                        fileObject: file
                    }]
                    Indicator.open({
                        text: '识别中...',
                        spinnerType: 'fading-circle'
                    });
                    uploadInstance.addAsync({
                        fileList: fileList,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                            if(result[0].status){
                                _this.ocrAsync(result)
                            }else{
                                Indicator.close()
                            }
                        }
                    })
                }
            };
            this.iJsBridge.call(options);
        },
        randomString(e) {  
            e = e || 32;
            let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"
            let a = t.length
            let n = "";
            for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
            return n
        },
        //将base64转换为blob
        dataURLtoBlob: function(base64) { 
                let bstr = window.atob(base64),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: "image/png" });
        },
        //将blob转换为file
        blobToFile: function(theBlob, fileName){
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        },
        //拍照
        graph() {
            this.$refs.btnGroup.openPop();
            let _this = this
            uploadInstance2.cameraFile({
                callback: data => {
                    _this.isShow = false
                    Indicator.open({
                        text: '处理中...',
                        spinnerType: 'fading-circle'
                    });
                    uploadInstance.addAsync({
                        fileList: data,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                            if(result[0].status){
                                console.log('result拍照',result);
                                _this.ocrAsync(result)
                            }else{
                                Indicator.close()
                            }
                        }
                    })
                }
            })
        },
        //相册选取
        album(txt) {
            let accepts = ''
            if(txt == "从相册选取"){
                SmartStorage.set('uploadMethod',0)
                this.$refs.btnGroup.openPop();
                accepts = 'image/*'
            }else if(txt == "本地文件上传"){
                SmartStorage.set('uploadMethod',1)
                this.$refs.btnGroup.openPop();
                accepts = '.pdf'
            }
            let _this = this
            uploadInstance.localUpload({
                accepts: accepts,
                callback: data => {
                    _this.isShow = false
                    Indicator.open({
                        text: '处理中...',
                        spinnerType: 'fading-circle'
                    });
                    uploadInstance.addAsync({
                        fileList: data,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                            if(result[0].status){
                                console.log('result相册选取2',result);
                                let Illegal = result.find(x=>{
                                    return x.content.fileType != '.pdf' && x.content.fileType != '.ofd' && x.content.fileType != '.PDF'&& x.content.fileType != '.OFD'
                                })
                                if(Illegal && SmartStorage.get('uploadMethod') == 1){
                                    Toast('只能上传pdf或者ofd格式文件')
                                    Indicator.close()
                                    return
                                }
                                _this.ocrAsync(result)
                            }else{
                                Indicator.close()
                            }
                        }
                    })
                }
            })
        },
        mappingAsync(result, fileInfo) {
            let mappingData = {
                processId: this.orderDetail.proposalId,
                itemId: this.orderDetail.itemId,
                relatedType: "Invoice",
                catalog: "发票"
            }
            if(this.orderDetail.addFile){
                mappingData.relatedType = 'Invoice_supplement'
                mappingData.relatedId = this.orderDetail.itemId
            }
            uploadInstance.mappingAsync({
                fileKeys: result[0].content ? [result[0].content.fileKey] : result,
                mappingData: mappingData,
                callback: (res) => {
                    Indicator.close()
                    if(res.success){
                        this.$emit("back", fileInfo)
                    }
                }
            })
        },
        ocrAsync(file) {
        let msg = '电子发票不能拍照上传，请在【电子发票上传】【微信卡包】中上传电子发票原件'
            if(!this.wxCardInfo.wxCardShow){
                msg = '电子发票不能拍照上传，请在【电子发票上传】中上传电子发票原件'
            }
            let _this = this
            uploadInstance.ocrAsync({
                url: file[0].content ? file[0].content.url : file[0].fileContent.url,
                callback: res => {
                    if(res.success && res.code != 210){
                        let hasElectronicInvoice = res.content.find(x=> ['增值税电子发票','增值税电子普通发票','增值税电子专用发票','电子发票(增值税专用发票)','电子发票(普通发票)', '区块链电子发票','收费公路通行费增值税电子普通发票','机打电子发票'].includes(x.invoiceType))
                        if(hasElectronicInvoice && SmartStorage.get('uploadMethod') == 0){
                            
                            Toast(msg)
                             Indicator.close()
                            return
                        }
                        res.content.map(item=>{
                            item.fileKey = file[0].content.fileKey
                            item.fileType = file[0].content.fileType
                            item.filePath = file[0].content.url
                        })
                        _this.mappingAsync(file,res.content)
                    }else{
                        Indicator.close()
                        Dialog({
                            title: "上传失败",
                            message: res.message
                        });
                    }
                }
            })
        },
        uploadPaperType() {//拍摄纸质发票
            SmartStorage.set('uploadMethod',0)
            let arr = []
            if(['browser','h5'].includes(SmartStorage.get('container')) || SmartStorage.get('isH5')){
                arr.push(this.btnList1[0])
            }else{
                arr = this.btnList1
            }
            if(this.event.tenant_name == '会引擎-灵北'){
                arr = this.btnList1
            }
            if(window.flutter_inappwebview){
                let uploadFileData = {
                    "hideCamera": false,
                    "hideGallery": arr.length > 1 ? false : true,
                    "hidePdfAndOfd": true
                }
                this.uploadFile(uploadFileData)
                return
            }
            this.openBtnGroup(arr)
        },
        uploadFileType() {//上传pdf电子发票
            SmartStorage.set('uploadMethod',1)
            if(window.flutter_inappwebview){
                let uploadFileData = {
                    "hideCamera": true,
                    "hideGallery": true,
                    "hidePdfAndOfd": false,
                }
                this.uploadFile(uploadFileData)
                return
            }
            this.openBtnGroup(this.btnList2)
        },
        // flutter上传
        async uploadFile(uploadFileData){
            let params = {
                "uploadUrl": process.env.VUE_APP_GATEWAY + '/foundation/aliyun/api/oss/upload', //图片上传的oss地址
                "hideCamera": uploadFileData.hideCamera || false,
                "hideGallery": uploadFileData.hideGallery || false,
                "hidePdfAndOfd": uploadFileData.hidePdfAndOfd || false,
            }
            let graph = await window.flutter_inappwebview.callHandler("App.openImagePicker",JSON.stringify(params));
            this.isShow = false
            if(graph && SmartStorage.get('uploadMethod') != 0){
                let Illegal = graph.find(x=>{return x.content.fileType != '.pdf' && x.content.fileType != '.ofd' && x.content.fileType != '.PDF'&& x.content.fileType != '.OFD'})
                if(Illegal){
                    Toast('只能上传pdf或者ofd格式文件')
                    Indicator.close()
                    return
                }
            }
            this.ocrAsync(graph)
        },
    }
};
</script>

<style lang="less" scoped>
.upload-invoice_page{
    .upload-invoice{
        width: 100%;
        background-color: #fff;
        padding-bottom: .1rem;
        .section-title{
            font-size: .16rem;
            text-align: left;
            padding: .25rem .0 0 2.5%
        }
        .upload-section{
            width: 95%;
            border-bottom: 1px solid  #F5F5F5;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0 auto;
            padding: .15rem 0;
            div{
                margin-right: .1rem;
            }
            img{
                width: 100%;
            }
            p{
                text-align: left;
                font-size: .16rem;
            }
            p:last-child{
                    font-size: .12rem;
                    color: #90939A;

            }
        }
    }
    .close{
        text-align: center;
        padding: .15rem 0;
        font-size: .16rem;
    }
}

</style>