const screenMixin = {
  data() {
    return {
      clientHeight: 0,
      originalHeight: 0,
      btnHidShow: true //是否显示底部
    };
  },

  watch: {
    //监听屏幕高度变化
    clientHeight(newHeight) {
      this.btnHidShow = this.originalHeight <= newHeight;
    }
  },
  mounted() {
    this.originalHeight = document.documentElement.clientHeight;
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('resize', this.resizeEvent);
  },
  methods: {
    handleResize() {
      this.clientHeight = document.documentElement.clientHeight;
    },
    resizeEvent() {
      if (document.activeElement.tagName == 'INPUT') {
        window.setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded();
        }, 100);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.resizeEvent);
  }
};
export default screenMixin;
