<template>
    <div>
        <Header backType="blank" :back="true" :backEvent="true" @backEvent="back"></Header>

        <div class="publicHead">
            <p>
                上传发票
                <Svgs
                    @click.native="openPop"
                    color="#FECE30"
                    classes="invoiceTips"
                    name="iconiconfontquestion"
                ></Svgs>
            </p>
        </div>
        <div class="normalInputRow" v-if="orderDetail.itemType !== 5">
            <MInput title="实际人数" pla="请输入实际人数" v-model="actualNumber"></MInput>
        </div>

        <div class="paymentInvoice">
            <viewer :images="invoiceFilesList" class="imgContainer">
                <template v-for="(list) in invoiceFilesList">
                    <div :key="list.url">
                        <img :src="list.url" alt/>
                        <Svgs
                            color='#FF484D'
                            classes="paymentDelete"
                            name="iconshanchu1"
                            @click.native="deleteInvoice(list)"
                        ></Svgs>
                    </div>
                </template>
            </viewer>
        </div>

        <div class="paymentBtnRow">
            <Btn btnClass="paymentUpload" @callBack="openBtnGroup()">
                <span slot="btnContent">
                    <Svgs color="#ffffff" classes="paymentUploadImg" name="icontupian"></Svgs>上传发票
                </span>
            </Btn>

            <Btn
                btnClass="paymentSubmit"
                @callBack="submitInvoice"
            >
                <span slot="btnContent">提交</span>
            </Btn>
        </div>

        <Pop ref="pop" :clickClose="true">
            <div slot="popContent">
                <Tips :qrCodeContent="qrCodeContent"></Tips>
            </div>
        </Pop>

        <MessageBox ref="remove" type="tips" messageTxt="确定删除图片？" @onOk="remove"></MessageBox>
        <BtnGroup ref="btnGroup" :btnList="btnList"></BtnGroup>
    </div>
</template>

<script>
import Tips from "./Tips";
import BtnGroup from "./BtnGroup";
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import { uploadInvocie } from "@/config/uploadInvocie.js";
import { UploadPlug } from "smart-filer";
import { SmartStorage } from 'smart-core-util'
const uploadInstance = new UploadPlug({
  host: process.env.VUE_APP_GATEWAY
});
export default {
    name: "UploadInvoice",
    components: {
        Tips,
        BtnGroup
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    data() {
        return {
            list: {},
            actualNumber: "",
            uploadBtnList: [
                {
                    txt: "拍照",
                    callback: this.graph
                },
                {
                    txt: "从相册选取",
                    callback: this.album
                }
            ],
            btnList: [],
            qrCodeContent: {},
            invoiceFilesList: []
        };
    },
    mounted() {
        this.queryCompany()
        this.getInvoiceFiles()
    },
    methods: {
        // 查询中智商展二维码信息
        queryCompany: async function() {
            const params = {
                proposalId: this.event.proposalId
            };
            miceService.invoiceGetCompany(params).then(res => {
                if (res && res.success) {
                    this.qrCodeContent = res.content;
                }
            });
        },
        getInvoiceFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        itemId: this.orderDetail.itemId,
                        catalog: "发票"
                    }
                },
                callback: data => {
                    this.invoiceFilesList = data.content
                }
            })

        },
       async openBtnGroup() {
            if(window.flutter_inappwebview){
                let uploadFileData = {
                    "hideCamera": false,
                    "hideGallery": false,
                    "hidePdfAndOfd": true
                }
                this.iJsBridge.uploadFile(uploadFileData,this.mappingAsync)
                return
            }
            this.btnList = this.uploadBtnList;
            this.$refs.btnGroup.openPop();
        },
        //拍照
        graph() {
            this.$refs.btnGroup.openPop();
            let _this = this
            uploadInstance.cameraFile({
                callback: data => {
                    uploadInstance.addAsync({
                        fileList: data,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                            _this.mappingAsync(result)
                        }
                    })
                }
            })
        },
        //相册选取
        album() {
            this.$refs.btnGroup.openPop();
            let _this = this
            uploadInstance.localUpload({
                callback: data => {
                    uploadInstance.addAsync({
                        fileList: data,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                           _this.mappingAsync(result)
                        }
                    })
                }
            })
        },
        mappingAsync(result) {
            uploadInstance.mappingAsync({
                fileKeys: [result[0].content.fileKey],
                mappingData: {
                    processId: this.orderDetail.proposalId,
                    itemId: this.orderDetail.itemId,
                    relatedType: "Invoice",
                    catalog: "发票"
                },
                callback: () => {
                    this.getInvoiceFiles()
                    this.saveInvoice(result)
                }
            })
        },
        deleteInvoice(list) {
            this.list = list
            this.$refs.remove.openPop();
        },
        remove() {
            uploadInstance.deleteAsync({
                fileItem: {
                    fileKey: this.list.fileKey
                },
                bucketAlias: "SmartEvent",
                callback: () => {
                    this.getInvoiceFiles()
                }
            })
        },
        // 提交发票
        submitInvoice() {
            let params = {
                itemId: this.orderDetail.itemId
            };
            if(this.orderDetail.itemType !== 5) {
                params.actualNumber = this.params.people
                if(!this.validate(params, uploadInvocie))return;
            }
            miceService.completeInvoice(params).then(res => {
                if (res && res.success) {
                    this.$router.push({ path:'/orderList' })
                }
            });
        },
        saveInvoice(result) {
            const params = {
                itemId: this.orderDetail.itemId,
                fileIds: [result[0].content.fileKey],
                noneTasks: true
            };
            miceService.saveInvoice(params).then(res => {
                if(res && res.success) {
                    console.log('保存成功')
                }
            })
        },
        openPop() {
            this.$refs.pop.changePop();
        },
        back() {
            this.$router.push({ path: "/orderList" });
        }
    }
};
</script>

<style>
@import "../../views/OrderSettle/OrderSettle.scss";

.invoiceTips {
    position: absolute;
    left: .9rem;
    top: .04rem;
}

.invoiceSubmit {
    margin-top: 0.1rem !important;
}

.paymentBtnRow {
    margin-bottom: 0 !important;
}
</style>