<template>
    <div class="orderSettle">
        <Header backType="blank" :back="true" :backEvent="true" @backEvent="back"></Header>
        <div class="orderSettle-container">
            <div class="publicHead">
                <p>
                    上传发票
                    <Svgs
                        @click.native="openPop"
                        color="#FECE30"
                        classes="invoiceTips"
                        name="iconiconfontquestion"
                    ></Svgs>
                    <Svgs
                        @click.native="openBtnGroup('phone')"
                        color="#FECE30"
                        classes="kefuTips"
                        name="iconkefu"
                    ></Svgs>
                </p>
            </div>
            <div class="normalInputRow">
                <MInput
                    title="发票类型"
                    pla="请选择发票类型"
                    :disabled="true"
                    @click.native="openBtnGroup('invoiceTypes')"
                    v-model="params.invoiceType"
                ></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="发票张数" pla="请输入发票张数" v-model="params.invoiceCount"></MInput>
            </div>

            <div class="paymentInvoice">
                <!--  -->
                <template v-for="(item, index) in invoiceData">
                    <div class="invoice-item-container" :key="index">
                        <div v-if="item.filePath" class="invoice-first">
                            <viewer :images="[item.filePath]" class="imgContainer">
                                <img :src="item.filePath" alt />
                            </viewer>
                        </div>
                        <div
                            v-else
                            @click="openBtnGroup('update', item)"
                            class="invoice-first update-invoice"
                        >
                            <Svgs color="#90939A" name="iconcamera1"></Svgs>
                            <div>点击上传</div>
                            <div>发票照片</div>
                        </div>
                        <div class="invoice-last">
                            <p>
                                <span>发票代码：</span>
                                {{item.invoiceCode?item.invoiceCode:"--"}}
                            </p>
                            <p>
                                <span>发票号码：</span>
                                {{item.invoiceNum?item.invoiceNum:'--'}}
                            </p>
                            <p>
                                <span>开票日期：</span>
                                {{item.billDate?item.billDate:'--'}}
                            </p>
                            <p>
                                <span>开票金额：</span>
                                <span style="color:orange">{{"￥"+ item.cost ? item.cost : "--"}}</span>
                            </p>
                            <p>
                                <span>校验码：</span>
                                {{item.checkCode?item.checkCode:'--'}}
                            </p>
                            <div class="invoice-delete-icon main" @click="deleteInvoice(item)">
                                <Svgs color="#fff" name="iconshanchu"></Svgs>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- </viewer> -->
            </div>
        </div>

        <div class="paymentBtnRow2">
            <Btn btnClass="paymentUpload" @callBack="addInvoice">
                <span slot="btnContent">
                    <Svgs color="#ffffff" classes="paymentUploadImg" name="icontupian"></Svgs>上传发票
                </span>
            </Btn>

            <Btn btnClass="paymentSubmit" @callBack="submitInvoice">
                <span slot="btnContent">提交</span>
            </Btn>
        </div>

        <Pop ref="pop" :clickClose="true">
            <div slot="popContent">
                <Tips :qrCodeContent="qrCodeContent"></Tips>
            </div>
        </Pop>

        <MessageBox ref="remove" type="tips" messageTxt="确认删除？" @onOk="remove"></MessageBox>
        <MessageBox ref="update" type="tips" btnType="single" singleTxt="点击上传发票照片" messageTitle="上传发票提醒" messageTxt="根据公司规定，所有扫描上传的发票也必须拍取发票照片上传至系统，请点击下面按钮上传发票照片！" @onSingle="uploadFile"></MessageBox>
        <BtnGroup ref="btnGroup" :btnList="btnList"></BtnGroup>
        <MessageBox ref="submitForm" type="textarea" txtColor='red' pal='请输入说明' :backFun="true" @onOk="submitSettle" messageTxt="您未在用餐当天上传发票，请填写说明："></MessageBox>

    </div>
</template>

<script>
import Tips from "./Tips";
import BtnGroup from "./BtnGroup";
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import { UploadPlug } from "smart-filer";
import { SmartStorage } from "smart-core-util";
import { indicator } from 'smart-indicator'
import {
    uploadInvocie,
    addInvocieNovartis,
    uploadInvocieNovartis
} from "@/config/uploadInvocie.js";
const uploadInstance = new UploadPlug({
    host: process.env.VUE_APP_GATEWAY
});
import camera from "@/utils/camera.js";
export default {
    name: "UploadInvoice",
    components: {
        Tips,
        BtnGroup
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    mixins: [camera],
    data() {
        return {
            list: {},
            invoiceTypes: [],
            params: {
                invoiceType: "",
                invoiceCount: ""
            },
            uploadDetailId: "",
            invoiceData: [],
            btnList: [],
            qrCodeContent: {},
            uploadBtnList: [
                {
                    txt: "拍照",
                    callback: this.graph
                },
                // {
                //     txt: "从相册选取",
                //     callback: this.album
                // }
            ]
        };
    },
    mounted() {
        //定额发票类型和数量
        const query = this.$route.query;
        this.params.invoiceType = query.invoiceType;
        this.params.invoiceCount = query.invoiceCount;
        this.invoiceGetTypes();
        this.queryCompany();
        this.invoiceQuery();
    },
    methods: {
        // 获取发票类型
        invoiceGetTypes() {
            const tenant_code = this.event.tenant_code || "pfizer";
            let params = {
                collection: "cfg-invoice-type-options",
                useCaching: false,
                cachingKey: "",
                filter: {
                    filterKey: 'novartisInvoiceType'
                },
                projection: {
                    options: 1
                }
            };
            miceService.getsettings(params).then(res => {
                if(res && res.success) {
                    res.content.options.forEach(item => {
                        if (item.val) {
                            this.invoiceTypes.push({
                                txt: item.val,
                                callback: this.getInvoiceType
                            });
                        }
                    });
                }
            });
        },
        getInvoiceType(value) {
            this.params.invoiceType = value;
            this.$refs.btnGroup.openPop();
        },
        // 查询中智商展二维码信息
        queryCompany: async function() {
            const params = {
                proposalId: this.event.proposalId
            };
            miceService.invoiceGetCompany(params).then(res => {
                if (res && res.success) {
                    this.qrCodeContent = res.content;
                }
            });
        },
        // 查询发票信息
        invoiceQuery(status) {
            this.invoiceData = [];
            let params = {
                proposalId: this.event.proposalId,
                itemId: this.orderDetail.itemId
            };
            miceService.invoiceQuery(params).then(res => {
                if (res.success) {
                    if (
                        !res.content ||
                        (res.content && res.content.length == 0)
                    )
                        return;
                    this.invoiceData = res.content;
                    // this.params.invoiceCount = this.invoiceData.length;
                    this.invoiceData.map(item => {
                        this.params.invoiceType = item.invoiceType;
                    });
                    if(status) {
                        this.invoiceData.map(item => {
                            if(item.status === 0) {
                                this.$refs.update.openPop();
                                this.uploadDetailId = item.detailId
                                return
                            }
                        });
                    }
                }
            });
        },
        // 打开选择弹框
        openBtnGroup(type, item) {
            if (type == "update") {
                this.btnList = this.uploadBtnList;
                if (item) {
                    this.uploadDetailId = item.detailId;
                }
            } else if (type == "invoiceTypes") {
                this.btnList = this.invoiceTypes;
            } else {
                this.btnList = [
                    {
                        txt: `客服热线${this.qrCodeContent.hotline}`,
                        callback: this.callPhone
                    }
                ];
            }
            this.$refs.btnGroup.openPop();
        },
        // 拨打电话
        callPhone() {
            this.ihtml.telCall(this.qrCodeContent.hotline);
        },
        // 点击添加发票按钮
        addInvoice() {
            let validateParams = this.params;
            validateParams.invoiceData = this.invoiceData;
            if (!this.validate(validateParams, addInvocieNovartis)) return;
            if (this.params.invoiceType.indexOf("增值税定额") > -1) {
                this.$router.push({
                    path: "/uploadQuotaInvocie",
                    query: {
                        invoiceType: this.params.invoiceType,
                        invoiceCount: this.params.invoiceCount
                    }
                });
            } else {
                this.openScan();
            }
        },
        uploadFile() {
            this.graph(true);
        },
        //拍照
        graph(flag) {
            if(window.flutter_inappwebview){
                let uploadFileData = {
                    "hideCamera": false,
                    "hideGallery": true,
                    "hidePdfAndOfd": true
                }
                this.iJsBridge.uploadFile(uploadFileData,this.handlePhoto)
                return
            }
            if (flag == '拍照') {
                this.$refs.btnGroup.openPop();
            }
            let _this = this;
            uploadInstance.cameraFile({
                callback: data => {
                    indicator.open()
                    uploadInstance.addAsync({
                        fileList: data,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: result => {
                            indicator.close()
                            _this.mappingAsync(result);
                            if(!_this.uploadDetailId) {
                                _this.saveInvoice(result[0].content);
                            }
                        }
                    });
                }
            });
        },
        handlePhoto(result){
            this.mappingAsync(result);
            if(!this.uploadDetailId) {
                this.saveInvoice(result[0].content);
            }
        },
        //相册选取
        album(flag) {
            if(window.flutter_inappwebview){
                let uploadFileData = {
                    "hideCamera": false,
                    "hideGallery": true,
                    "hidePdfAndOfd": true
                }
                this.iJsBridge.uploadFile(uploadFileData,this.handlePhoto)
                return
            }
            if (flag == '从相册选取') {
                this.$refs.btnGroup.openPop();
            }
            let _this = this
            uploadInstance.localUpload({
                callback: data => {
                    uploadInstance.addAsync({
                        fileList: data,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                           _this.mappingAsync(result);
                            if(!_this.uploadDetailId) {
                                _this.saveInvoice(result[0].content);
                            }
                        }
                    })
                }
            })
        },
        //扫描
        async openScan() {
            let _this = this;
            // const options = {
            //     loadUrl: "",
            //     method: "SMBarcodeScaner.scan",
            //     postData: {
            //         showCameraBtn: true,
            //         hintText: "若发票二维码无法识别\n请拍照上传"
            //     },
            //     callback: data => {
            //         if (data.type == "QR_CODE" && data.text) {
            //             //上传扫描的二维码
            //             _this.invoiceVerify(data.text);
            //         }
            //         if (data.type == "camera") {
            //             _this.openBtnGroup('update')
            //         }
            //     }
            // };
            // this.iJsBridge.call(options);
            let postData = {
                showCameraBtn: true,
                hintText: "若发票二维码无法识别\n请拍照上传"
            }
            if (window.flutter_inappwebview) {
                let data = await window.flutter_inappwebview.callHandler("App.openQrScanner", JSON.stringify(postData));
                if(data == '' || data == 'user_cancel') return
                if (data == "user_turn_camera") {
                    _this.graph()
                    return
                }
                if (data) {
                    //上传扫描的二维码
                    _this.invoiceVerify(data);
                }
            } else {
                const options = {
                    loadUrl: "",
                    method: "SMBarcodeScaner.scan",
                    postData: postData,
                    callback: data => {
                        if (data.type == "QR_CODE" && data.text) {
                            //上传扫描的二维码
                            _this.invoiceVerify(data.text);
                        }
                        if (data.type == "camera") {
                            _this.openBtnGroup('update')
                        }
                    }
                };
                this.iJsBridge.call(options);
            }
        },
        // 发票验真
        invoiceVerify(qrcodeResult) {
            const params = {
                itemId: this.orderDetail.itemId,
                data: qrcodeResult
            };
            miceService.invoiceVerify(params).then(res => {
                if (!res.success) return;
                this.invoiceQuery(true);
            });
        },
        mappingAsync(result) {
            uploadInstance.mappingAsync({
                fileKeys: [result[0].content.fileKey],
                mappingData: {
                    processId: this.orderDetail.proposalId,
                    itemId: this.orderDetail.itemId,
                    relatedType: "Invoice",
                    catalog: "发票"
                },
                callback: () => {
                    if(this.uploadDetailId) {
                        this.invoiceMapFile(result);
                    }
                }
            });
        },
        deleteInvoice(item) {
            this.list = item;
            this.$refs.remove.openPop();
        },
        async remove() {
            let item = this.list;
            if (item.filePath) {
                const filePath = item.filePath;
                const index = filePath.lastIndexOf("/");
                const fileKey = filePath.substring(index + 1, filePath.length);
                const res = await this.deleteFile(fileKey);
                if (!res.success) return;
            }
            const params = {
                detailId: item.detailId
            };
            miceService.deleteInvoice1(params).then(res => {
                if (res.success) {
                    let params2 = {
                        FileKey: item.fileId
                    }
                    miceService.deleteInvoice12(params2).then(res => {
                        this.invoiceQuery();
                    });
                }
            });
        },
        // 提交发票
        submitInvoice() {
            const filterInvoiceData = this.invoiceData.filter(item => {
                if (
                    item.invoiceType.indexOf("增值税定额") == -1 &&
                    item.status == 0
                ) {
                    return true;
                }
            });
            if (filterInvoiceData && filterInvoiceData.length > 0) {
                this.toast("请上传发票照片");
                return;
            }
            let date= new Date().toLocaleDateString()
            let receivePlanTime = new Date(this.orderDetail.receivePlanTime).toLocaleDateString()
            console.log('receivePlanTime',receivePlanTime);
            let validateParams = this.params;
            validateParams.invoiceData = this.invoiceData;
            if (!this.validate(validateParams, uploadInvocieNovartis)) return;
            if(date != receivePlanTime && this.orderDetail.receivePlanTime){
                this.$refs.submitForm.openPop(); 
                return
            }
            let endTime = new Date(this.orderDetail.dtEnd).toLocaleDateString()
            if(new Date().getTime() > new Date(endTime).getTime() + 86400000 && !this.orderDetail.receivePlanTime){
                this.$refs.submitForm.openPop(); 
                return
            }
            this.completeInvoice()
        },
        submitSettle(data){
            if(!data.value){
                this.toast("请填写说明");   
                return
            }
            let params = {
                itemId: this.orderDetail.itemId,
                InvoiceReason: data.value
            }
            miceService.saveItemData(params).then(res => {
                if (res && res.success) {
                    this.$refs.submitForm.openPop()
                    this.completeInvoice()
                }
            });
        },
        completeInvoice(){
            let validateParams = this.params;
            validateParams.invoiceData = this.invoiceData;
            let params = this.params;
            params.invoiceData && delete params.invoiceData;
            params.itemId = this.orderDetail.itemId;
            miceService.completeInvoice(params).then(res => {
                if (res && res.success) {
                    this.back();
                }
            });
        },
        // 保存发票
        saveInvoice(result) {
            let params = {
                itemId: this.orderDetail.itemId,
                invoiceType: this.params.invoiceType,
                invoiceCode: "",
                invoiceNum: "",
                fileIds: [result.fileKey]
            };
            miceService.saveInvoice(params).then(res => {
                if (res && res.success) {
                    this.invoiceQuery();
                }
            });
        },
        // 发票验真上传图片回调
        invoiceMapFile(result) {
            const params = {
                fileIds: [result[0].content.fileKey],
                detailId: this.uploadDetailId
            };
            miceService.invoiceMapFiles(params).then(res => {
                if (res && res.success) {
                    this.invoiceQuery();
                    this.uploadDetailId = "";
                }
            });
        },
        openPop() {
            this.$refs.pop.changePop();
        },
        back() {
            this.$router.push({ path: "/orderList" });
        }
    }
};
</script>

<style lang="scss">
@import "../../views/OrderSettle/OrderSettle.scss";
.invoice-item-container {
    position: relative;
    border-radius: 0.13rem;
    margin-top: 0.2rem;
    box-shadow: 5px 10px 0.15rem #f5f5f5;
    padding: 0.15rem;
    display: flex;

    .update-invoice {
        background-color: #f5f5f5;
        padding-top: 0.11rem;
        box-sizing: border-box;

        div {
            font-size: 12px;
            color: #90939a;
        }
    }

    .invoice-first {
        margin-right: 0.15rem;
        width: 0.8rem;
        height: 0.8rem;
    }
    .invoice-last {
        color: #13161b;
        position: relative;
        width: calc(100% - 1rem);
        span {
            color: #90939a;
        }
        p {
            text-align: left;
        }

        .invoice-delete-icon {
            position: absolute;
            border-radius: 50%;
            height: 0.44rem;
            width: 0.44rem;
            right: 0;
            top: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.invoiceTips {
    position: absolute;
    left: 0.9rem;
    top: 0.04rem;
}

.kefuTips {
    position: absolute;
    right: 0.3rem;
    top: 0.04rem;
}

.invoiceSubmit {
    margin-top: 0.1rem !important;
}
</style>