import { filer, geoLocation} from 'smart-filer'
export default {
    extends: geoLocation,
    computed: {},
    methods: {
        //打开拍照
        openCamera() {
            this.$refs.filecamera1.click()
            
        },
        //打开文件选择
        openAlbum() {
            this.$refs.filegallery1.click()
        },
        //input file change事件回调方法
        async addFile(e) {
            const fileData = e.target.files[0]
            const bucketAlias = 'SmartEvent'
            const _filer = new filer({});
            const mappingData = {
                relatedType: "settle",
                catalog: "发票",
                processId: this.orderDetail.proposalId,
                itemId: this.orderDetail.itemId                
            }
            // console.log(mappingData)

            //调用singleUpload方法
            const uploadResult =  await _filer.singleUpload(
                fileData, //文件数据
                bucketAlias, //oss bucket 别名
                mappingData, //文件mapping数据
                this.lat, //经度
                this.lng, //纬度
                this.uploadSuccess //调用singleUpload方法回调函数
            )
            
        },
        async deleteFile(fileKey) {
            const bucketAlias = 'SmartEvent'
            const _filer = new filer({});
            const deleteResult = await _filer.singleDelete({fileKey: fileKey,bucketAlias: bucketAlias})
            return deleteResult
        },
        uploadSuccess(mappingResult, uploadData) {
            if(!mappingResult.success)return;
            this.saveInvoice(uploadData.content)
        }
    },
}