 <template>
    <div class="EditInvoice" id="editInvoiceBox">
        <Header backType="white" :back="true" :backEvent="true" @backEvent="backToList">
            <span slot="headText" style="font-size:.17rem;">编辑发票</span>
        </Header>
        <div class="container">
            <div class="normalInputRow">
                <MInput title="购买方名称" :labelBlack="true" v-if="item.invoiceType != '增值税定额发票'" invoiceStyle="true" must="true" pla="请输入购买方名称" v-model="item.purchaserName"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="纳税人登记号" :labelBlack="true" v-if="item.invoiceType != '增值税定额发票'" invoiceStyle="true" must="true" pla="请输入纳税人登记号" v-model="item.purchaserTaxId"></MInput>
            </div>
            <div class="normalInputRow" v-if="item.invoiceCode">
                <MInput title="发票代码" :labelBlack="true" type="tel" invoiceStyle="true" must="true" pla="请输入发票代码" v-model="item.invoiceCode"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="发票号码" :labelBlack="true" type="tel" invoiceStyle="true" must="true" pla="请输入发票号码" v-model="item.invoiceNum"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="发票金额" :labelBlack="true" invoiceStyle="true" must="true" type='number' pla="请输入发票金额" v-model="item.amount"></MInput>
            </div>
        </div>
        <div class="editInvoice_submit_btn" v-if="btnHidShow" @click.stop="submit()">
            确定
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import screenMixin from '@/utils/getScreen.js';
export default {
    name:'EditInvoice',
    mixins:[screenMixin],
    props: [
        'item',
        'saveInvoice'
    ],
    data() {
        return {
            input: {
                qty1: "",
                amount: "",
            },
            optionalType: [],
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    methods: {
        // 智会云（天津）会议展览有限公司    91120222MA069UF33M
        backToList() {
            this.$emit("back")
        },
        submit(){
            this.$emit("saveInvoice",this.item)
        }
    }
};
</script>
<style lang="scss">
.EditInvoice {
    width: 100%;
    height: 100%;
    .container {
        width: 95%;
        height: calc(100% - 1.3rem);
        margin: 0 auto;
    }
    .editInvoice_submit_btn {
        width: 95vw;
        line-height: 0.5rem;
        border-radius: 0.04rem;
        margin: 0 auto;
        box-shadow: 0px 4px 22px 0px rgba(0,0,0,0.2);
        background: var(--themeColor);
        color: #FFFFFF;
    }
}
</style>