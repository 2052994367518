<template>
    <component :is="uploadInvoicex"></component>
</template>
<script>
import { mapGetters } from "vuex";
import uploadInvoice_eventcool from '@/template/UploadInvoice/eventcool.vue'
import uploadInvoice_novartis from '@/template/UploadInvoice/novartis.vue'
import uploadInvoice_dscl from '@/template/UploadInvoice/Dscl.vue'
import uploadInvoice_crossevent from '@/template/UploadInvoice/crossevent.vue'
import uploadInvoice_pfizer from '@/template/UploadInvoice/pfizer.vue'
import uploadInvoice_ocr from '@/template/UploadInvoice/ocr.vue'
import uploadInvoice_ocr2 from '@/template/UploadInvoice/ocr2.vue'
import { getCookie } from 'tiny-cookie'
import { miceService } from "@/service/miceService.js";
import {
    SmartStorage
} from 'smart-core-util'
export default {
    components: {
        uploadInvoice_eventcool,
        uploadInvoice_novartis,
        uploadInvoice_dscl,
        uploadInvoice_pfizer,
        uploadInvoice_crossevent,
        uploadInvoice_ocr,
        uploadInvoice_ocr2
    },
    data() {
        return{
            OCR: false
        }
    },
    computed: {
        ...mapGetters(['event','mealRules']),
        uploadInvoicex: {
             get: function() {
                var componentId = '';
                if(this.mealRules.newUI){
                    componentId = "2"
                }
                return ('uploadInvoice_ocr' + componentId);  
            },   
            
        }
    },
    async created() {
        // 配置ocr租户 
        // this.OCR = await this.getOCRSetting()
    },
    methods: {
        // getOCRSetting(){
        //     let params = {
        //         collection: "cfg-imeal-channel",
        //         useCaching: false,
        //         cachingKey: "",
        //         filter: {
        //             'txt': 'ocr租户列表',
        //             'tenantCodes': {
        //                 tenant_id: getCookie('tenant_id')
        //             },
        //         },
        //         projection: {
        //             restaurantType: 1
        //         }
        //     };
        //     if(SmartStorage.get('gray') || getCookie('gray')){
        //             params.filter.txt = 'ocr租户列表-gray'
        //     }
        //     return new Promise((resolve, reject) => {   
        //         miceService.getOptItems(params).then(res => {
        //             if (res && res.success && res.content) {
        //                 resolve(res.content.length)
        //             }
        //         }).catch(err => {
        //             reject(false)
        //         })
        //     })
        // },  
    }
}
</script>