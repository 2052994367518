<template>
    <div class="orderSettle">
        <Header backType="blank" :back="true" :backEvent="true" @backEvent="back"></Header>
        <div class="orderSettle-container">
            <div class="publicHead">
                <p>
                    上传发票
                </p>
                <div class="upload-tips" @click="openPop">
                    注意事项
                    <Svgs
                        color="#000000"
                        name="iconfontdown"
                    ></Svgs>
                </div>
            </div>
            <!-- <div class="normalInputRow" v-if="orderDetail.itemType !== 5">
                <MInput title="实际人数" pla="请输入实际人数" v-model="actualNumber"></MInput>
            </div> -->
            <div class="upload-sub-title">上传发票</div>
            <div class="paymentInvoice1">
                <viewer :images="invoiceFilesList" class="imgContainer">
                    <template v-for="(list) in invoiceFilesList">
                        <div :key="list.url">
                            <img :src="list.url" alt/>
                            <Svgs
                                color='#FF484D'
                                classes="paymentDelete"
                                name="iconshanchu1"
                                @click.native="deleteInvoice(list)"
                            ></Svgs>
                        </div>
                    </template>
                    <div class="uploadImg" @click="openBtnGroup()">
                        <Svgs
                            color="#FFFFFF"
                            name="iconadd-copy"
                        ></Svgs>
                    </div>
                </viewer>
            </div>
            <div class="qr-code-content">
                <div class="qr-code">
                    <div class="qr-code-title">智会云开票信息</div>
                    <p>发票抬头：{{qrCodeContent.name}}</p>
                    <p>纳税人登记号：{{qrCodeContent.taxPayerCode}}</p>
                    <img v-if="event.tenant_name == '会引擎-huarun'" src="../../assets/img/zhihuiyun.jpg"/>
                </div>
            </div>
        </div>

        <div class="paymentBtnRow3">
            <!-- <Btn btnClass="paymentUpload" @callBack="openBtnGroup()">
                <span slot="btnContent">
                    <Svgs color="#ffffff" classes="paymentUploadImg" name="icontupian"></Svgs>上传发票
                </span>
            </Btn> -->

            <Btn
                btnClass="payment-submit"
                @callBack="submitInvoice"
            >
                <span slot="btnContent">提交</span>
            </Btn>
        </div>

        <Pop ref="pop" :clickClose="true">
            <div slot="popContent">
                <Tips :qrCodeContent="qrCodeContent"></Tips>
            </div>
        </Pop>

        <MessageBox ref="remove" type="tips" messageTxt="确定删除图片？" @onOk="remove"></MessageBox>
        <BtnGroup ref="btnGroup" :btnList="btnList"></BtnGroup>
    </div>
</template>

<script>
import Tips from "./Tips";
import BtnGroup from "./BtnGroup";
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import { uploadInvocie } from "@/config/uploadInvocie.js";
import { UploadPlug } from "smart-filer";
import { SmartStorage } from 'smart-core-util'
const uploadInstance = new UploadPlug({
  host: process.env.VUE_APP_GATEWAY
});
export default {
    name: "UploadInvoice",
    components: {
        BtnGroup,
        Tips
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    data() {
        return {
            list: {},
            actualNumber: "",
            uploadBtnList: [
                {
                    txt: "拍照",
                    callback: this.graph
                },
                {
                    txt: "从相册选取",
                    callback: this.album
                }
            ],
            btnList: [
                {
                    txt: "拍照",
                    callback: this.graph
                },
                {
                    txt: "从相册选取",
                    callback: this.album
                }],
            qrCodeContent: {},
            invoiceFilesList: [],
            tips: [{
                txt: "请务必清晰拍照，提交后不能再修改！" 
            }, {
                txt: "请将发票带回公司，纸质发票需投递至办事处信箱，或快递至中智公司；"
            }],
        };
    },
    mounted() {
        this.queryCompany()
        this.getInvoiceFiles()
    },
    methods: {
        // 查询中智商展二维码信息
        queryCompany: async function() {
            const params = {
                proposalId: this.event.proposalId
            };
            miceService.invoiceGetCompany(params).then(res => {
                if (res && res.success) {
                    this.qrCodeContent = res.content;
                }
            });
        },
        getInvoiceFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        itemId: this.orderDetail.itemId,
                        catalog: "发票"
                    }
                },
                callback: data => {
                    this.invoiceFilesList = data.content
                }
            })

        },
        async openBtnGroup() {
            if(window.flutter_inappwebview){
                let uploadFileData = {
                    "hideCamera": false,
                    "hideGallery": false,
                    "hidePdfAndOfd": true
                }
                this.iJsBridge.uploadFile(uploadFileData,this.mappingAsync)
                return
            }
            this.$refs.btnGroup.openPop();
        },
        //拍照
        graph() {
            this.$refs.btnGroup.openPop();
            let _this = this
            uploadInstance.cameraFile({
                callback: data => {
                    uploadInstance.addAsync({
                        fileList: data,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                            _this.mappingAsync(result)
                        }
                    })
                }
            })
        },
        //相册选取
        album() {
            this.$refs.btnGroup.openPop();
            let _this = this
            uploadInstance.localUpload({
                callback: data => {
                    uploadInstance.addAsync({
                        fileList: data,
                        ossKey: "",
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                           _this.mappingAsync(result)
                        }
                    })
                }
            })
        },
        mappingAsync(result) {
            uploadInstance.mappingAsync({
                fileKeys: [result[0].content.fileKey],
                mappingData: {
                    processId: this.orderDetail.proposalId,
                    itemId: this.orderDetail.itemId,
                    relatedType: "Invoice",
                    catalog: "发票"
                },
                callback: () => {
                    this.getInvoiceFiles()
                    this.saveInvoice(result)
                }
            })
        },
        deleteInvoice(list) {
            this.list = list
            this.$refs.remove.openPop();
        },
        remove() {
            uploadInstance.deleteAsync({
                fileItem: {
                    fileKey: this.list.fileKey
                },
                bucketAlias: "SmartEvent",
                callback: () => {
                    this.getInvoiceFiles()
                }
            })
        },
        // 提交发票
        submitInvoice() {
            let params = {
                itemId: this.orderDetail.itemId
            };
            // if(this.orderDetail.itemType !== 5) {
            //     params.actualNumber = this.actualNumber
            //     if(!this.validate(params, uploadInvocie))return;
            // }
            miceService.completeInvoice(params).then(res => {
                if (res && res.success) {
                    let backUrl = '/orderList'
                     if(SmartStorage.get("isH5")){
                        backUrl = '/mealOrderDetail'
                    }
                    this.$router.push({ path: backUrl })
                }
            });
        },
        saveInvoice(result) {
            const params = {
                itemId: this.orderDetail.itemId,
                fileIds: [result[0].content.fileKey],
                noneTasks: true
            };
            miceService.saveInvoice(params).then(res => {
                if(res && res.success) {
                    console.log('保存成功')
                }
            })
        },
        openPop() {
            this.$refs.pop.changePop();
        },
        back() {
            // if(!SmartStorage.get('isH5')){
                this.$router.push({ path: "/orderList" });
            // }else{
            //     this.$router.back()
            // }
        }
    }
};
</script>

<style>
@import "../../views/OrderSettle/OrderSettle.scss";

.invoiceTips {
    position: absolute;
    left: .9rem;
    top: .04rem;
}

.invoiceSubmit {
    margin-top: 0.1rem !important;
}

.paymentBtnRow {
    margin-bottom: 0 !important;
}

.qr-code-content {
    padding: 0.25rem;
}
.qr-code {
    border-radius: 0.13rem;
    background: #F5F5F5;
    color: #909399;
    padding: 0.17rem;
}
.qr-code p {
    text-align: left;
    margin: 0.04rem 0;
    font-size: 0.12rem;
}
.qr-code-title {
    text-align: left;
    font-size: 0.16rem;
}
.qr-code img {
    margin: 0.04rem 0;
    width: 50%;
}
</style>