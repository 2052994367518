<template>
    <div class="uploadInvoice">
        <div v-show="!showAddInvoicePage" class="orderSettle">
            <Header backType="blank" :back="true" :backEvent="true" @backEvent="back">
            <span
                @click="submitInvoice"
                slot="rightBtn"
            >
                提交
            </span>
            </Header>
            <div class="orderSettle-container">
                <div class="head-tips">
                    <p v-for="(tipsItem,index) in tips" :key="index">
                        <span>*</span>{{ tipsItem.txt }}
                    </p>
                </div>
                <div class="add-invoice" @click="addInvoice"> + 点击添加发票</div>
                <div v-for="(item,index) in invoiceFilesList" :key="index" class="invoice-block">
                    <div>
                        <div class="invoice-block-main">
                            <div class="invoice-thumbnail">
                                <div v-if="item.fileType == '.ofd'">
                                    <img src="../../assets/img/ofd.png" style="width:.6rem;height:.6rem"/>                                </div>
                                <div v-if="item.fileType == '.pdf'" @click="() => openPdfThum(item.filePath)">
                                    <i-icon style="width:.6rem;height:.6rem" class="icon-pdf" color="var(--themeColor)" name="icon-pdf" />
                                </div>
                                <viewer v-else :images="[item.filePath]" class="imgContainer">
                                    <img v-for="(src,index) in [item.filePath]" :src="src" :key="index" alt/>
                                </viewer>
                            </div>
                            <div class="invoice-info">
                                <div>
                                    <MInput title="发票号码" @change="priceChange(item)" type="tel" invoiceStyle="true" must="true" pla="请输入发票号码" v-model="item.invoiceNum"></MInput>
                                </div>
                                <div>
                                    <MInput title="发票金额" @change="priceChange(item)" invoiceStyle="true" must="true" type='number2' pla="请输入发票金额" v-model="item.amount"></MInput>
                                </div>
                                <div>
                                    <MInput title="购买方名称" @change="priceChange(item)" v-if="item.invoiceType != '增值税定额发票'" invoiceStyle="true" must="true" pla="请输入购买方名称" v-model="item.purchaserName"></MInput>
                                </div>
                                <div>
                                    <MInput title="纳税人登记号" @change="priceChange(item)" v-if="item.invoiceType != '增值税定额发票'" invoiceStyle="true" must="true" pla="请输入纳税人登记号" v-model="item.purchaserTaxId"></MInput>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-block-btn">
                            <button class="delete-invoice" @click="() => deleteInvoice(item)">删除</button>
                            <button class="save-invoice" @click="() => saveInvoice(item)">保存修改</button>
                        </div>
                    </div>
                </div>
                <div class="qr-code-content">
                    <div class="qr-code">
                        <div class="qr-code-title">智会云开票信息</div>
                        <p>发票抬头：{{qrCodeContent.name}}</p>
                        <p>纳税人登记号：{{qrCodeContent.taxPayerCode}}</p>
                        <p>开户银行：{{qrCodeContent.bankName}}</p>
                        <p>开户账号：{{qrCodeContent.bankAccount}}</p>
                        <p>地址：{{qrCodeContent.address}}</p>
                        <img v-if="event.tenant_name == '会引擎-huarun'" src="../../assets/img/zhihuiyun.jpg"/>
                    </div>
                </div>
            </div>

            <div v-if="pdfShow" class="view-pdf" @click="closePdfThum">
                <div class="pdf-content">
                </div>
            </div>
        </div>
        <AddInvoice v-if="showAddInvoicePage" @back="currentInvoiceAll"/>
        <MessageBox ref="submitForm" type="textarea" txtColor='red' pal='请输入说明' :backFun="true" @onOk="submitSettle" messageTxt="您未在用餐当天上传发票，请填写说明："></MessageBox>

    </div>
</template>

<script>
// import Pdf from "vue-pdf";
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import AddInvoice from "./addInvoice.vue";
import { MessageBox,Toast,Indicator } from 'mint-ui';
import { Radio  } from 'mint-ui';
import Vue from 'vue';
import {
    SmartStorage
} from 'smart-core-util'
Vue.component(Radio.name, Radio);
export default {
    name: "UploadInvoice",
    components: {
        // Pdf,
        AddInvoice,
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    data() {
        return {
            checked: '电子发票',
            pdfShow: false,
            pdfShowUrl: "",
            showAddInvoicePage: false,
            invoiceFilesList:[],
            qrCodeContent: {},
            tips: [{
                txt: "请务必清晰拍照，提交后不能再修改！" 
            }, {
                txt: "请将发票带回公司，纸质发票需投递至办事处信箱，或快递至中智公司；"
            }],
        };
    },
    mounted() {
        if(this.event.tenant_name == '会引擎-huarun'){
            this.tips = [
            { txt: "请务必清晰拍照，提交后不能再修改！" },
            { txt: "纸质发票需邮寄！" }
            ]
        }
        this.queryCompany()
        this.invoiceQuery()
    },
    methods: {
        priceChange(item){
            console.log(item);
            item.priceChangeFlag = true
        },
        // 查询发票信息
        invoiceQuery() {
            this.invoiceData = [];
            let params = {
                proposalId: this.event.proposalId,
                itemId: this.orderDetail.itemId
            };
            miceService.invoiceQuery(params).then(res => {
                if (res.success) {
                    this.invoiceFilesList = res.content;
                }
            });
        },
        // 查询中智商展二维码信息
        queryCompany: async function() {
            const params = {
                proposalId: this.event.proposalId
            };
            miceService.invoiceGetCompany(params).then(res => {
                if (res && res.success) {
                    this.qrCodeContent = res.content;
                }
            });
        },
        back() {
            this.$router.push({ path: "/orderList" });
        },
        deleteInvoice(invoice) {
            MessageBox.confirm('确定删除当前发票 ?').then(action => {
                this.deleteCurrentInvoice(invoice)
            }); 
        },
        async deleteCurrentInvoice(invoice) {
            const params = {
                detailId: invoice.detailId
            };
            miceService.deleteInvoice1(params).then(res => {
                if (res.success) {
                    let params2 = {
                        FileKey: invoice.fileId
                    }
                    miceService.deleteInvoice12(params2).then(res => {
                        this.invoiceQuery();
                    });
                }
            });
        },
        saveInvoice(invoice) {
            if(!invoice.invoiceNum){
                Toast('请输入发票号码');
                return
            }
            var str = this.qrCodeContent.name.replace(/（|）|\(|\)/g,"");
            var str2 = (invoice.purchaserName || '').replace(/（|）|\(|\)/g,"");
            if(str != str2 && invoice.invoiceType != '增值税定额发票'){
                Toast('您发票上的购买方名称和开票信息不符，请确认或重新开具正确发票');
                return
            }
            if(this.qrCodeContent.taxPayerCode != invoice.purchaserTaxId && invoice.invoiceType != '增值税定额发票'){
                Toast('您发票上的购买方名称和开票信息不符，请确认或重新开具正确发票');
                return
            }
            if(!isNaN(invoice.invoiceNum) && (invoice.invoiceNum.toString().length == 8 || invoice.invoiceNum.toString().length == 20)){
                MessageBox.confirm('确定保存修改 ?').then(action => {
                    invoice.priceChangeFlag = false
                    this.currentInvoiceAll([invoice])
                });
            }else{
                Toast('请输入8位或20位的正确发票号码');
            }
        },
        saveCurrentInvoice(invoice,config,index) {
            let params = {
                itemId: this.orderDetail.itemId,
                invoiceType: invoice.invoiceType,
                invoiceCode: invoice.invoiceCode,
                invoiceNum: invoice.invoiceNum,
                checkCode: invoice.checkCode,
                cost: invoice.totalAmount || invoice.cost,
                tax: invoice.totalTax || invoice.tax,
                amount: invoice.amountInFiguers || invoice.amount,
                billDate: invoice.invoiceDate || invoice.billDate,
                purchaserName: invoice.purchaserName,
                purchaserTaxId: invoice.purchaserRegisterNum || invoice.purchaserTaxId,
                salesName: invoice.sellerName || invoice.salesName,
                salesTaxId: invoice.sellerRegisterNum || invoice.salesTaxId,
                salesContact: invoice.sellerAddress || invoice.salesContact,
                salesBank: invoice.sellerBank,
                fileIds: [invoice.fileKey],
                uploadMethod: SmartStorage.get('uploadMethod')
            
            };
            if(invoice.detailId){
                params.detailId = invoice.detailId
            }
            return new Promise((resolve,reject) => {
                miceService.saveInvoice(params).then(res => {
                    if(!res.success && res.msg == '当前发票号已存在'){
                        let params2 = {
                            FileKey: invoice.fileKey
                        }
                        miceService.deleteInvoice12(params2).then(res => {
                        });
                    }
                    if (res && res.success) {
                        // this.invoiceQuery();
                        resolve(res)
                    }else{
                        resolve([res, config,index])

                    }
                }).catch(err => {
                    resolve([err, config,index])
                });
            })
        },
        async waitCurrentInvoice(invoice, config, index) {
            let n = await this.saveCurrentInvoice(invoice, config, index);
            return n;
        },
        submitInvoice() {
            // if(this.checked == '纸质发票'){
            //     MessageBox.confirm('确定提交 ?').then(action => {
            //         this.submitInvoiceService()
            //     });
            //     return
            // }
            let allAmont = 0
            for(let item of [...this.invoiceFilesList]){
                if(item.priceChangeFlag){
                    Toast('请保存修改过的发票');
                    return
                }
                var str = this.qrCodeContent.name.replace(/（|）|\(|\)/g,"");
                var str2 = (item.purchaserName || '').replace(/（|）|\(|\)/g,"");
                if(str != str2 && item.invoiceType != '增值税定额发票'){
                    Toast('您发票上的购买方名称和开票信息不符，请确认或重新开具正确发票');
                    return
                }
                if(this.qrCodeContent.taxPayerCode != item.purchaserTaxId && item.invoiceType != '增值税定额发票'){
                    Toast('您发票上的购买方名称和开票信息不符，请确认或重新开具正确发票');
                    return
                }
                allAmont = allAmont + item.amount
            }
            console.log("---settle",allAmont * 10000 - this.orderDetail.settle * 10000 );
            if(
                this.orderDetail.itemType == '7' &&  -10 * 10000 > allAmont * 10000 - this.orderDetail.settle * 10000
            ){
                Toast('发票总金额和结算金额不符，无法提交，请重新检查发票信息');
                return
            }
            console.log("---realAmount",allAmont * 10000 - this.orderDetail.realAmount * 10000 );
            if(
                this.orderDetail.itemType == '5' && this.orderDetail.realAmount * 10000 > allAmont * 10000
            ){
                Toast('发票总金额和结算金额不符，无法提交，请重新检查发票信息');
                return
            }
            if(['novartis'].includes(this.event.tenant_code)){
                let date= new Date().toLocaleDateString()
                let receivePlanTime = new Date(this.orderDetail.receivePlanTime).toLocaleDateString()
                if(date != receivePlanTime && this.orderDetail.receivePlanTime){
                    this.$refs.submitForm.openPop(); 
                    return
                }
                let endTime = new Date(this.orderDetail.dtEnd).toLocaleDateString()
                if(new Date().getTime() > new Date(endTime).getTime() + 86400000 && !this.orderDetail.receivePlanTime){
                    this.$refs.submitForm.openPop(); 
                    return
                }
            }
            MessageBox.confirm('确定提交 ?').then(action => {
                this.submitInvoiceService()
            });
        },
        // 山德士上传说明
        submitSettle(data){
            if(!data.value){
                this.toast("请填写说明");   
                return
            }
            let params = {
                itemId: this.orderDetail.itemId,
                InvoiceReason: data.value
            }
            miceService.saveItemData(params).then(res => {
                if (res && res.success) {
                    this.$refs.submitForm.openPop()
                    this.submitInvoiceService()
                }
            });
        },
        // 提交发票
        submitInvoiceService() {
            let params = {
                itemId: this.orderDetail.itemId
            };
            if(['novartis'].includes(this.event.tenant_code)){
                params.invoiceType = '发票类型'
                params.invoiceCount = this.invoiceFilesList.length
            }
            
            // if(this.checked == '纸质发票'){
            //     params.isCheckInvoice = false
            // }else{
            //     params.isCheckInvoice = true
            // }
            miceService.completeInvoice(params).then(res => {
                if (res && res.success) {
                    if(SmartStorage.get('isH5')){
                        this.$router.push(
                            `/success`
                        )
                        return
                    }
                    let backUrl = '/orderList'
                    this.$router.push({ path: backUrl })
                }
            });
        },
        openPdfThum(pdfUrl) {
            this.iJsBridge.openPdfImg(pdfUrl)
            // window.open(pdfUrl)
            // this.pdfShowUrl = pdfUrl
            // this.$nextTick(()=>{
            //     this.pdfShow = true
            // })
        },
        closePdfThum(e) {
            if(e.target.className == "view-pdf"){
                this.pdfShow = false
            }
        },
        addInvoice() {
            this.showAddInvoicePage = true
        },
        currentInvoiceAll(e) {
            console.log("返回",e);
            if(!e){
                this.showAddInvoicePage = false
                return
            }
            // this.showAddInvoicePage = false
            Indicator.open({
                text: '处理中...',
                spinnerType: 'fading-circle'
            });
            if(e){
                let arr = []
                for(let i = 0; i < e.length; i++){
                    arr.push(this.waitCurrentInvoice(e[i],{},i))
                }
                Promise.all(arr)
                .then(res => {
                    this.invoiceQuery();
                    this.showAddInvoicePage = false
                    Indicator.close()
                })
                .catch(err => {
                    console.log("error", err);
                });
                // e.forEach(item => {
                //     this.saveCurrentInvoice(item)
                // });
                
            }
        }
    }
};
</script>

<style lang="less" scoped>
.uploadInvoice{

    .remind{
    margin-bottom: 1.5rem;
    span{
        background: yellow;
    }
}
.mint-radiolist{
    display: flex;
     justify-content: center
}
.publicHead2 {
    margin-top: .15rem;
    margin-bottom: .1rem;
}

.publicHead2 p {
    text-align: left;
    font-size: .2rem;
    margin-left: .25rem;
    position: relative;
}
    .head-tips{
        padding: .05rem .1rem;
        p{
            text-align: left;
            margin-bottom: 0.05rem;
            color: #FC3258;
            font-size: .12rem;
            span{
                margin-right: .02rem;
            }
        }
    }
    .add-invoice{
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
        overflow: hidden;
        background: var(--themeColor);
        color: #fff;
        width: 80%;
        margin: 0.2rem auto;
        padding: 0.1rem 0;
        border-radius: 0.2rem;
    }
    .invoice-block{
        margin: 0.25rem;
        > div{
            // background-color:#F5F5F5;
            border: 1px solid var(--themeColor);
            border-radius: 0.13rem;
        }
        .invoice-block-main{
            display: flex;
            justify-content: flex-start;
            border-bottom: 1px solid var(--themeColor);
            .invoice-thumbnail{
                width: .8rem;
                padding: .1rem;
                .imgContainer{
                    width: inherit;
                    img{
                        width: inherit;
                    }
                }
            }
            .invoice-info{
                padding-top: .1rem;
                flex-grow: 1;
                width: min-content;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                > div{
                    text-align: left;
                    // border-bottom: 1px solid var(--themeColor);
                    overflow: hidden;
                    &:last-child{
                        border-bottom: none;
                    }
                    > span{
                        > div{
                            padding: 0;
                        }
                    }
                }
            }
        }
        .invoice-block-btn{
            display: flex;
            justify-content: flex-end;
            padding: .1rem;
            button{
                padding: .05rem .2rem;
                margin-left: .1rem;
                border-radius: .05rem;
                &.delete-invoice{
                    background-color: #FFFFFF;
                    color: var(--themeColor);
                    border: 1px solid var(--themeColor);
                }
                &.save-invoice{
                    background-color: var(--themeColor);
                    color: #FFFFFF;
                }
            }
        }
    }
    .view-pdf{
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 9;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: rgba(0, 0, 0, .5);
        .pdf-content{
            transform: rotate(90deg) scale(1.3);
        }
    }
    .qr-code-content {
        padding: 0.25rem;
    }
    .qr-code {
        border-radius: 0.13rem;
        background: #F5F5F5;
        color: #909399;
        padding: 0.17rem;
    }
    .qr-code p {
        text-align: left;
        margin: 0.04rem 0;
        font-size: 0.12rem;
    }
    .qr-code-title {
        text-align: left;
        font-size: 0.16rem;
    }
}
</style>