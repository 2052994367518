<template>
    <div class="uploadInvoice2">
        <div v-if="!showEditPage">
            <Header backType="white" :back="true" :backEvent="true" @backEvent="back">
            <span slot="headText" style="font-size:.17rem;">{{ orderDetail.addFile ? '补传发票' : '上传发票'}}</span>
            </Header>
            <div class="orderSettle-container">
            <AttentionTips :tips="tips" :newUI="true" />
                <MInput v-if="['roche','pfizer'].includes(event.tenant_code) && orderDetail.itemType == 7 && !orderDetail.addFile" title="实际人数" :labelBlack="true" must="true" pla="请输入实际人数" type='fla' v-model="qty"></MInput>
                <div class="add-invoice" @click="addInvoice"><i-icon style="width:.15rem;height:.15rem" class="icon-pdf" color="var(--themeColor)" name="iconhao" /> 添加发票</div>                    

                <div v-for="(item,index) in invoiceFilesList" :key="index" class="invoice-block">
                    <div>
                        <div class="invoice-block-main">
                            <div class="invoice-thumbnail">
                                <div v-if="item.fileType == '.ofd'">
                                    <img src="../../assets/img/ofd.png" style="width:.6rem;height:.6rem"/>                                </div>
                                <div v-else-if="item.fileType == '.pdf'" @click="() => openPdfThum(item.filePath)">
                                    <i-icon style="width:.6rem;height:.6rem" class="icon-pdf" color="var(--themeColor)" name="icon-pdf" />
                                </div>
                                <viewer v-else :images="[item.filePath]" class="imgContainer">
                                    <img v-for="(src,index) in [item.filePath]" :src="src" :key="index" alt/>
                                </viewer>
                            </div>
                            <div class="invoice-info">
                                <p class="invoice_purchaserName">
                                    {{ item.purchaserName }}
                                </p>
                                <p class="invoice_purchaserTaxId">
                                    税号&emsp;{{ item.purchaserTaxId }}
                                </p>
                                <p class="invoice_num" v-if="item.invoiceCode">
                                    发票代码&emsp;{{ item.invoiceCode }}
                                </p>
                                <p class="invoice_num">
                                    发票号码&emsp;{{ item.invoiceNum }}
                                </p>
                                <p class="invoice_amount">
                                    <span>{{ item.amount }}</span>&nbsp;元
                                </p>
                            </div>
                        </div>
                        <div class="invoice-block-btn">
                            <button class="delete-invoice" @click="() => deleteInvoice(item)">删除</button>
                            <button class="save-invoice" @click="() => editInvoice(item)">编辑</button>
                        </div>
                    </div>
                </div>

                <div class="invoice_txt">
                    <div>
                        智会云开票信息 
                        <!-- <div @click="copy(qrCodeContent.name)">复制抬头</div> -->
                    </div>
                    <p class="copy_">
                        发票抬头：{{qrCodeContent.name}}
                        <span @click="copy(qrCodeContent.name)">复制</span>
                    </p>
                    <p>纳税人登记号：{{qrCodeContent.taxPayerCode}}
                        <span @click="copy(qrCodeContent.taxPayerCode)">复制</span>
                    </p>
                    <!-- <p>开户银行：{{qrCodeContent.bankName}}</p>
                    <p>开户账号：{{qrCodeContent.bankAccount}}</p>
                    <p>地址：{{qrCodeContent.address}}</p> -->
                    <img v-if="event.tenant_name == '会引擎-huarun'" src="../../assets/img/zhihuiyun.jpg"/>
                </div>
            </div>

            <div v-if="pdfShow" class="view-pdf" @click="closePdfThum">
                <div class="pdf-content">
                </div>
            </div>
            
        <div class="submit_btn" @click.stop="submitInvoice">
            {{
                orderDetail.addFile ? '确定' : '提交'
            }}
        </div>
        </div>
        <AddInvoice ref="addInvoice" @back="currentInvoiceAll"/>
        <EditInvoice v-if="showEditPage" @saveInvoice="saveInvoice" :item="editItem" @back="showEditPage = false"/>
        <MessageBox ref="submitForm" type="textarea" txtColor='red' pal='请输入说明' :backFun="true" @onOk="submitSettle" messageTxt="您未在用餐当天上传发票，请填写说明："></MessageBox>

    </div>
</template>

<script>
// import Pdf from "vue-pdf";
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import AddInvoice from "./addInvoice2.vue";
import EditInvoice from "./EditInvoice.vue";
import { MessageBox,Toast,Indicator } from 'mint-ui';
import { Radio  } from 'mint-ui';
import Vue from 'vue';
import {
    SmartStorage
} from 'smart-core-util'
Vue.component(Radio.name, Radio);
export default {
    name: "UploadInvoice",
    components: {
        // Pdf,
        AddInvoice,
        EditInvoice
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    data() {
        return {
            checked: '电子发票',
            pdfShow: false,
            pdfShowUrl: "",
            showAddInvoicePage: false,
            showEditPage: false,
            invoiceFilesList:[],
            qrCodeContent: {},
            editItem: {}, 
            tips: ["* 请务必清晰拍照，提交后不能再修改！" ,"* 请将发票带回公司，纸质发票需投递至办事处信箱，或快递至中智公司；"],
            qty:'',
        };
    },
    mounted() {
        if(this.event.tenant_name == '会引擎-huarun'){
            this.tips = ["* 请务必清晰拍照，提交后不能再修改！","* 纸质发票需邮寄！"]
        }
        this.queryCompany()
        this.invoiceQuery()
    },
    methods: {
        editInvoice(item){
            this.editItem = JSON.parse(JSON.stringify(item))
            this.showEditPage = true
        },
		copy(txt){
			let input = document.createElement('input')
			input.setAttribute('readonly','readonly')
			input.setAttribute('value',txt)
			input.style.top = '-999999999999px'
			input.style.left = '-999999999999px'
			input.style.opacity = 0
			input.style.position = 'fixed'
			document.body.appendChild(input)
			input.select()
			document.execCommand('Copy')
			Toast('复制成功')
			input.remove() 
		},
        // 查询发票信息
        invoiceQuery() {
            this.invoiceData = [];
            let params = {
                proposalId: this.event.proposalId,
                itemId: this.orderDetail.itemId
            };
            if(this.orderDetail.addFile){
                params.relatedType = 'Invoice_supplement'
            }
            miceService.invoiceQuery(params).then(res => {
                if (res.success) {
                    if(this.orderDetail.addFile){
                        var arr = []
                        res.content.map((item) => {
                            if(item.relatedType == 'Invoice_supplement'){
                                arr.push(item)
                            }
                        })
                        this.invoiceFilesList = arr;
                    }else{
                        this.invoiceFilesList = res.content;
                    }
                }
            });
        },
        // 查询中智商展二维码信息
        queryCompany: async function() {
            const params = {
                proposalId: this.event.proposalId
            };
            miceService.invoiceGetCompany(params).then(res => {
                if (res && res.success) {
                    this.qrCodeContent = res.content;
                }
            });
        },
        back() {
            if(this.orderDetail.addFile){
                this.$router.go(-1)
                return
            }
            this.$router.push({ path: "/orderList" });
        },
        deleteInvoice(invoice) {
            MessageBox.confirm('确定删除当前发票 ?').then(action => {
                this.deleteCurrentInvoice(invoice)
            }); 
        },
        async deleteCurrentInvoice(invoice) {
            const params = {
                detailId: invoice.detailId
            };
            miceService.deleteInvoice1(params).then(res => {
                if (res.success) {
                    let params2 = {
                        FileKey: invoice.fileId
                    }
                    miceService.deleteInvoice12(params2).then(res => {
                        this.invoiceQuery();
                    });
                }
            });
        },
        saveInvoice(invoice) {
            if(!invoice.invoiceNum){
                Toast('请输入发票号码');
                return
            }
            var str = this.qrCodeContent.name.replace(/（|）|\(|\)/g,"");
            var str2 = (invoice.purchaserName || '').replace(/（|）|\(|\)/g,"");
            if(str != str2 && invoice.invoiceType != '增值税定额发票'){
                Toast('您发票上的购买方名称和开票信息不符，请确认或重新开具正确发票');
                return
            }
            if(this.qrCodeContent.taxPayerCode != invoice.purchaserTaxId && invoice.invoiceType != '增值税定额发票'){
                Toast('您发票上的购买方名称和开票信息不符，请确认或重新开具正确发票');
                return
            }
            if(!invoice.amount){
                Toast('请输入正确的发票金额');
                return
            }
            if(!isNaN(invoice.invoiceNum) && (invoice.invoiceNum.toString().length == 8 || invoice.invoiceNum.toString().length == 20)){
                MessageBox.confirm('确定保存修改 ?').then(action => {
                    this.currentInvoiceAll([invoice])
                });
            }else{
                Toast('请输入8位或20位的正确发票号码');
            }
        },
        saveCurrentInvoice(invoice,config,index) {
            let params = {
                itemId: this.orderDetail.itemId,
                invoiceType: invoice.invoiceType,
                invoiceCode: invoice.invoiceCode,
                invoiceNum: invoice.invoiceNum,
                checkCode: invoice.checkCode,
                cost: invoice.totalAmount || invoice.cost,
                tax: invoice.totalTax || invoice.tax,
                amount: invoice.amountInFiguers || invoice.amount,
                billDate: invoice.invoiceDate || invoice.billDate,
                purchaserName: invoice.purchaserName,
                purchaserTaxId: invoice.purchaserRegisterNum || invoice.purchaserTaxId,
                salesName: invoice.sellerName || invoice.salesName,
                salesTaxId: invoice.sellerRegisterNum || invoice.salesTaxId,
                salesContact: invoice.sellerAddress || invoice.salesContact,
                salesBank: invoice.sellerBank,
                fileIds: [invoice.fileKey || invoice.fileId],
                uploadMethod: SmartStorage.get('uploadMethod')
            
            };
            if(invoice.detailId){
                params.detailId = invoice.detailId
            }
            if(this.orderDetail.addFile){
                params.fileRelatedType = "Invoice_supplement"
            }
            return new Promise((resolve,reject) => {
                miceService.saveInvoice(params).then(res => {
                    if(!res.success && res.msg == '当前发票号已存在'){
                        let params2 = {
                            FileKey: invoice.fileKey
                        }
                        miceService.deleteInvoice12(params2).then(res => {
                        });
                    }
                    if (res && res.success) {
                        // this.invoiceQuery();
                        resolve(res)
                    }else{
                        resolve([res, config,index])

                    }
                }).catch(err => {
                    resolve([err, config,index])
                });
            })
        },
        async waitCurrentInvoice(invoice, config, index) {
            let n = await this.saveCurrentInvoice(invoice, config, index);
            return n;
        },
        submitInvoice() {
            // if(this.checked == '纸质发票'){
            //     MessageBox.confirm('确定提交 ?').then(action => {
            //         this.submitInvoiceService()
            //     });
            //     return
            // }
            
            if(this.invoiceFilesList.length == 0 && !this.orderDetail.addFile){
                Toast('请先上传发票');
                return
            }
            if(['roche','pfizer'].includes(this.event.tenant_code) && !this.qty && this.orderDetail.itemType === 7 && !this.orderDetail.addFile){
                Toast('请填写实际人数');
                return
            }

            let allAmont = 0
            for(let item of [...this.invoiceFilesList]){
                var str = this.qrCodeContent.name.replace(/（|）|\(|\)/g,"");
                var str2 = (item.purchaserName || '').replace(/（|）|\(|\)/g,"");
                if(str != str2 && item.invoiceType != '增值税定额发票'){
                    Toast('您发票上的购买方名称和开票信息不符，请确认或重新开具正确发票');
                    return
                }
                if(this.qrCodeContent.taxPayerCode != item.purchaserTaxId && item.invoiceType != '增值税定额发票'){
                    Toast('您发票上的购买方名称和开票信息不符，请确认或重新开具正确发票');
                    return
                }
                allAmont = allAmont + item.amount
            }
            console.log("---settle",allAmont * 10000 - this.orderDetail.settle * 10000 );
            if(
                this.orderDetail.itemType == '7' &&  -10 * 10000 > allAmont * 10000 - this.orderDetail.settle * 10000 && !this.orderDetail.addFile
            ){
                Toast('发票总金额和结算金额不符，无法提交，请重新检查发票信息');
                return
            }
            console.log("---realAmount",allAmont * 10000 - this.orderDetail.realAmount * 10000 );
            if(
                this.orderDetail.itemType == '5' && this.orderDetail.realAmount * 10000 > allAmont * 10000 && !this.orderDetail.addFile
            ){
                Toast('发票总金额和结算金额不符，无法提交，请重新检查发票信息');
                return
            }
            if(this.orderDetail.addFile){
                this.$router.go(-1)
                return
            }
            if(['novartis'].includes(this.event.tenant_code)){
                let date= new Date().toLocaleDateString()
                let receivePlanTime = new Date(this.orderDetail.receivePlanTime).toLocaleDateString()
                if(this.orderDetail.sourceCode == 'ELE'){
                    receivePlanTime = new Date(this.orderDetail.modifiedOn).toLocaleDateString() 
                }
                if(date != receivePlanTime && this.orderDetail.receivePlanTime){
                    this.$refs.submitForm.openPop(); 
                    return
                }
                let endTime = new Date(this.orderDetail.dtEnd).toLocaleDateString()
                if(new Date().getTime() > new Date(endTime).getTime() + 86400000 && !this.orderDetail.receivePlanTime && this.orderDetail.itemType != 7){
                    this.$refs.submitForm.openPop(); 
                    return
                }
            }
            MessageBox.confirm('确定提交 ?').then(action => {
                this.submitInvoiceService()
            });
        },
        // 山德士上传说明
        submitSettle(data){
            if(!data.value){
                this.toast("请填写说明");   
                return
            }
            let params = {
                itemId: this.orderDetail.itemId,
                InvoiceReason: data.value
            }
            miceService.saveItemData(params).then(res => {
                if (res && res.success) {
                    this.$refs.submitForm.openPop()
                    this.submitInvoiceService()
                }
            });
        },
        // 提交发票
        submitInvoiceService() {
            let params = {
                itemId: this.orderDetail.itemId
            };
            if(['roche','pfizer'].includes(this.event.tenant_code) && this.orderDetail.itemType === 7 && this.orderDetail.sourceCode ==='DianPing'){
                params.actualNumber = this.qty;
                params.hasFiles = true;
            }
            if(['novartis'].includes(this.event.tenant_code)){
                params.invoiceType = '发票类型'
                params.invoiceCount = this.invoiceFilesList.length
            }
            
            // if(this.checked == '纸质发票'){
            //     params.isCheckInvoice = false
            // }else{
            //     params.isCheckInvoice = true
            // }
            miceService.completeInvoice(params).then(res => {
                if (res && res.success) {
                    if(SmartStorage.get('isH5')){
                        this.$router.push(
                            `/success`
                        )
                        return
                    }
                    let backUrl = '/orderList'
                    this.$router.push({ path: backUrl })
                }
            });
        },
        openPdfThum(pdfUrl) {
            this.iJsBridge.openPdfImg(pdfUrl)
            // window.open(pdfUrl)
            // this.pdfShowUrl = pdfUrl
            // this.$nextTick(()=>{
            //     this.pdfShow = true
            // })
        },
        closePdfThum(e) {
            if(e.target.className == "view-pdf"){
                this.pdfShow = false
            }
        },
        addInvoice() {
            this.$refs.addInvoice.show(true)
        },
        currentInvoiceAll(e) {
            if(!e){
                this.$refs.addInvoice.show(false)
                return
            }
            Indicator.open({
                text: '处理中....',
                spinnerType: 'fading-circle'
            });
            if(e){
                let arr = []
                for(let i = 0; i < e.length; i++){
                    arr.push(this.waitCurrentInvoice(e[i],{},i))
                }
                Promise.all(arr)
                .then(res => {
                    this.invoiceQuery();
                    this.$refs.addInvoice.show(false)
                    this.showEditPage = false
                    Indicator.close()
                })
                .catch(err => {
                    console.log("error", err);
                });
                // e.forEach(item => {
                //     this.saveCurrentInvoice(item)
                // });
                
            }
        }
    }
};
</script>

<style lang="less" scoped>
.uploadInvoice2{
    height: 100%;
    .remind{
    margin-bottom: 1.5rem;
    span{
        background: yellow;
    }
}
.mint-radiolist{
    display: flex;
     justify-content: center
}
.publicHead2 {
    margin-top: .15rem;
    margin-bottom: .1rem;
}

.publicHead2 p {
    text-align: left;
    font-size: .2rem;
    margin-left: .25rem;
    position: relative;
}
    .head-tips{
        padding: .05rem .1rem;
        p{
            text-align: left;
            margin-bottom: 0.05rem;
            color: #FC3258;
            font-size: .12rem;
            span{
                margin-right: .02rem;
            }
        }
    }
    .add-invoice{
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        background: var(--opacityColor);
        color: var(--themeColor);
        width: 95%;
        margin: 0.2rem auto;
        padding: 0.15rem 0;
        border-radius: 0.05rem;
        font-size: .16rem;
    }
    .invoice-block{
        width: 95%;
        margin: .08rem auto;
        > div{
            box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
            border-radius: 0.05rem;
        }
        .invoice-block-main{
            display: flex;
            justify-content: flex-start;
            border-bottom: 1px solid #F5F5F5;
            padding: .14rem;
            .invoice-thumbnail{
                width: .6rem;
                height: .6rem;
                .imgContainer{
                    width: inherit;
                    img{
                        width: inherit;
                        height: .6rem;
                        box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.1);
                        border-radius: .1rem;
                    }
                }
            }
            .invoice-info{
                width: calc(100% - .8rem);
                margin-left: .1rem;
                .invoice_purchaserName{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    text-align: left;
                    font-size: .15rem;
                    font-weight: bold;  
                }
                .invoice_purchaserTaxId{
                    color: #909399;
                    text-align: left;
                    font-size: .14rem;
                    line-height: .2rem;
                    margin-bottom: .05rem;
                }
                .invoice_num{
                    text-align: left;
                    font-size: .14rem;
                }
                .invoice_amount{
                    text-align: left;
                    color: #909399;
                    font-size: .14rem;
                    margin-top: .1rem;
                    span{
                        font-weight: bold;
                        color: #13161B;
                        font-size: .16rem;
                    }
                }
            }
        }
        .invoice-block-btn{
            display: flex;
            justify-content: flex-end;
            padding: .15rem;
            button{
                padding: .05rem .2rem;
                margin-left: .1rem;
                border-radius: .05rem;
                &.delete-invoice{
                    border: 1px solid #DEDEDE;
                    color: #909399;
                    background: #fff
                }
                &.save-invoice{
                    background-color: var(--themeColor);
                    color: #FFFFFF;
                }
            }
        }
    }
    .view-pdf{
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 9;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: rgba(0, 0, 0, .5);
        .pdf-content{
            transform: rotate(90deg) scale(1.3);
        }
    }
    .invoice_txt{
        width: 95%;
        margin: 0 auto;
        text-align: left;
        padding-bottom: 1rem;
        >div{
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: .06rem;
                font-size: .16rem;
            // >div{
            //     height: .3rem;
            //     width: .8rem;
            //     line-height: .3rem;
            //     text-align: center;
            //     background: rgba(56,117,254,0.15);  
            //     color: #3875FE;
            //     font-size: .12rem;
            //     border-radius: .05rem;
            // }
        }
        p{
            text-align: left;
            line-height: .25rem;
            display: flex;
            span{
                height: .2rem;
                width: .4rem;
                line-height: .2rem;
                text-align: center;
                background: var(--opacityColor);
                color: var(--themeColor);
                font-size: .12rem;
                border-radius: .02rem;
                display:block;
                margin-left: .1rem
            }

        }
    }
    .submit_btn {
        position: fixed;
        bottom: 0;
        width: 95vw;
        height: 0.5rem;
        line-height: 0.5rem;
        border-radius: 0.04rem;
        margin: 0.15rem 2.5vw 0.25rem 2.5vw;
        box-shadow: 0px 4px 22px 0px rgba(0,0,0,0.2);
        background: var(--themeColor);
        color: #FFFFFF;
    }
}
</style>