export const uploadInvocie = {
    'actualNumber': {
        required: true,
        description: '实际人数不能为空',
    }
}

export const uploadInvocieNovartis = {
    'invoiceType': {
        required: true,
        description: '发票类型不能为空',
    },
    'invoiceCount': {
        required: true,
        description: '发票张数不能为空',
        expression: function(m) {
            if(m.invoiceType.indexOf('增值税定额') > -1 || !m.invoiceData || (m.invoiceData && m.invoiceData.length==0))return false;
            return m.invoiceCount != m.invoiceData.length
        },
        expressionMsg: '实际发票张数与录入发票张数不符' 
    },
    'invoiceData': {
        required: false,
        expression: function(m){
            const invoiceData = m.invoiceData.filter((item) => {
                if(item.invoiceType != m.invoiceType) {
                    return true;
                }
            })
            return invoiceData && invoiceData.length>0
        },
        expressionMsg: '请删除不同类型发票信息'        
    }
}

export const addInvocieNovartis = {
    'invoiceType': {
        required: true,
        description: '发票类型不能为空'
    },
    'invoiceCount': {
        required: true,
        description: '发票张数不能为空',
        expression: function(m) {
            if(m.invoiceType.indexOf('增值税定额') > -1 || !m.invoiceData || (m.invoiceData && m.invoiceData.length==0))return false;
            return m.invoiceCount <= m.invoiceData.length
        },
        expressionMsg: '超出录入发票张数上限' 
    },
    'invoiceData': {
        required: false,
        expression: function(m){
            const invoiceData = m.invoiceData.filter((item) => {
                if(item.invoiceType != m.invoiceType) {
                    return true;
                }
            })
            return invoiceData && invoiceData.length>0
        },
        expressionMsg: '请删除不同类型发票信息'        
    }    
}

export const uploadQuotaInvocie = {
    'invoiceCode': {
        required: true,
        description: '发票代码不能为空'
    },
    'invoiceNum': {
        required: true,
        description: '发票号码不能为空'
    },
    'hasFiles': {
        required: true,
        description: '请上传至少一张发票'
    }     
}

export const saveQty = {
    'qty': {
        required: true,
        description: '实际人数不能为空',
    },
}